<script setup>
import { ref, watch, onMounted } from 'vue';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

const { relationships, provinces } = storeToRefs(useOptionsStore());

const props = defineProps({
    initialContacts: {
        type: Array,
        default: () => []
    }
});

const contacts = ref([]);

onMounted(() => {
    contacts.value = props.initialContacts;
});

const emit = defineEmits(['contacts-updated']);
watch(contacts, (newVal) => {
    emit('contacts-updated', newVal);
}, { deep: true });

const addContact = () => {
    contacts.value.push({
        relationship_id: null,
        first_name: '',
        last_name: '',
        telephone_number: '',
        email: '',
        address: '',
        city: '',
        postal_code: '',
        province_id: null,
    });
};

const removeContact = (index) => {
    contacts.value.splice(index, 1);
};
</script>

<template>
<div>
    <div v-for="(contact, index) in contacts" :key="index" class="border border-gray-300 rounded-lg p-4 mb-4">
        <div class="md:grid lg:grid-cols-2 xl:grid-cols-3 md:gap-4">
            <StyledDropdown 
                v-model="contact.relationship_id"
                :options="relationships"
                :label="$t('label.relationship')"
                :name="`contact.${index}.relationship_id`" />
            <StyledInput 
                v-model="contact.first_name"
                :label="$t('label.first_name')"
                :name="`contacts.${index}.first_name`" />
            <StyledInput 
                v-model="contact.last_name"
                :label="$t('label.last_name')"
                :name="`contacts.${index}.last_name`" />
            <StyledInput 
                v-model="contact.telephone_number"
                :label="$t('label.phone')"
                :name="`contacts.${index}.telephone_number`" />
            <StyledInput 
                v-model="contact.email"
                :label="$t('label.email')"
                :name="`contacts.${index}.email`" />
            <StyledInput 
                v-model="contact.address"
                :label="$t('label.address')"
                :name="`contacts.${index}.address`" />
            <StyledInput 
                v-model="contact.city"
                :label="$t('label.city')"
                :name="`contacts.${index}.city`" />
            <StyledInput 
                v-model="contact.postal_code"
                :label="$t('label.postal_code')"
                :name="`contacts.${index}.postal_code`" />
            <StyledDropdown 
                v-model="contact.province_id"
                :options="provinces"
                :label="$t('label.province')"
                :name="`contacts.${index}.province_id`"
                item-value="id"
                item-title="name" />
            <StyledButton 
                color="accent" 
                @click="removeContact(index)">
                {{ $t('label.remove_contact') }}
            </StyledButton>
        </div>
    </div>
    <div class="bg-grey-200 my-4 pb-12 pt-12 px-12 border-black border border-dashed rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-100" @click="addContact">
        <StyledButton class="flex items-center">
            <v-icon class="mr-2 text-white" icon="mdi-plus" />
            {{ $t('label.add_contact') }}
        </StyledButton>
    </div>
</div>
</template>
    
