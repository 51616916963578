<script setup>
import { ref, watch, onMounted } from 'vue';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

const { relationships } = storeToRefs(useOptionsStore());

const props = defineProps({
    initialAttendees: {
        type: Array,
        default: () => []
    }
});

const attendees = ref([]);

onMounted(() => {
    attendees.value = props.initialAttendees;
});

const emit = defineEmits(['attendees-updated']);
watch(attendees, (newVal) => {
    emit('attendees-updated', newVal);
}, { deep: true });

const addAttendee = () => {
    attendees.value.push({
        name: '',
        relationship_id: null,
    });
};

const removeAttendee = (index) => {
    attendees.value.splice(index, 1);
};
</script>

<template>
<div>
    <div v-for="(attendee, index) in attendees" :key="index" class="border border-gray-300 rounded-lg p-4 mb-4">
        <div class="mt-2 md:mt-5 grid grid-cols-1 md:grid-cols-3 md:gap-4">
            <StyledInput 
                v-model="attendee.name"
                :label="$t('label.name')"
                :name="`attendees.${index}.name`" />
            <StyledDropdown 
                v-model="attendee.relationship_id"
                :options="relationships"
                :label="$t('label.relationship')"
                :name="`attendees.${index}.relationship_id`" />
            <div class="text-red-500 mt-2 cursor-pointer hover:underline">
                <v-icon icon="mdi-trash-can-outline" @click="removeAttendee(index)" />
            </div>
        </div>
    </div>
    <div class="bg-grey-200 my-4 pb-12 pt-12 px-12 border-black border border-dashed rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-100" @click="addAttendee">
        <v-icon class="mr-2 text-gray-500" icon="mdi-plus" />
        <span class="text-gray-500">{{ $t('label.add_attendee') }}</span>
    </div>
</div>
</template>
