<script setup>
import { ref, inject, onMounted } from 'vue';
import { useFormStore } from '@/stores/form';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

import { trans } from 'laravel-vue-i18n';

const { all } = storeToRefs(useFormStore());
const { templateTypes } = storeToRefs(useOptionsStore());

import { useToast } from 'vue-toastification';
const toast = useToast();

const { 
    searchClientForm,
    searchIntakeTourForms,
    searchSupportPlanForms,
    searchSupportPlanGoalEventForms,
    searchTimelineForms,
    getFormOptions,
    generateFormFromTemplate,
} =
    useFormStore();

const props = defineProps({
    formable: {
        type: Object,
        default: () => null,
    },
    type: {
        type: String,
        required: true,
    },
});

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    dateFrom: null,
    dateTo: null,
    per_page: 10,
});

const searchForms = () => {
    let action = null;

    switch (props.type) {
    // This functions against their "application"
    case 'client':
        action = searchClientForm;
        break;
    // This functions against the client's actual "Intake Tour"
    case 'intake-tour':
        action = searchIntakeTourForms;
        break;
    case 'support-plan':
        action = searchSupportPlanForms;
        break;
    case 'support-plan-goal-event':
        action = searchSupportPlanGoalEventForms;
        break;
    case 'timeline':
        action = searchTimelineForms;
        break;
    }

    if (!props.formable) {
        return;
    }

    action({
        hash: props.formable.hash,
        ...params.value,
    });
};

// When we load the component, we want to search for the forms
onMounted(async () => {
    await getFormOptions();
    await searchForms();
});

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchForms();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, {
        page,
        sort_by: sortBy,
        per_page: itemsPerPage,
    });
    searchForms();
};

// ====================================================
//        Form Actions
// ====================================================
const viewForm = (_) => {
    // todo!
};

const deleteForm = (_) => {
    // todo!
};

const downloadForm = (_) => {
    // todo!
};

const selectNewForm = async () => {
    modals.show('TemplateFormPickerModal', {
        props: {
            type: props.type,
        }
    }).then(async (template) => {
        try {            
            await generateFormFromTemplate({
                template_hash: template.hash,
                formable_hash: props.formable.hash,
                type: props.type,
            });

            searchForms();
        } catch (e) {
            toast.error(trans('label.error'));
        }
    });
};

</script>

<template>
<MainContentLayout>
    <template #title>{{ $t('label.forms') }}</template>
    <template #actions>
        <StyledButton
            @click="selectNewForm"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{ $t("label.new_form") }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-4 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchForms"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.type"
            :label="$t('label.type')"
            :placeholder="$t('label.type')"
            :options="templateTypes"
            name="type"
            @input="searchForms" />
        <StyledDatepicker
            v-model="params.dateFrom"
            :label="$t('label.date_from')"
            view-mode="month"
            name="date_from"
            @input="searchForms" />
        <StyledDatepicker
            v-model="params.dateTo"
            :disabled="!params.dateFrom"
            :label="$t('label.date_to')"
            view-mode="month"
            name="date_to"
            @input="searchForms" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            {
                title: $t('label.title'),
                key: 'title',
                value: (item) => `${item.title}`,
                sortable: false,
            },
            {
                title: $t('label.type'),
                key: 'type',
                value: (item) => `${item.template.type.name}`,
                sortable: false,
            },
            {
                title: $t('label.version'),
                key: 'version',
                value: (item) => `v${item.template.version_info.version}`,
                sortable: false,
            },
            {
                title: $t('label.date_added'),
                key: 'created_at',
                value: (item) => `${item.created_at.split('T')[0]}`,
                sortable: false,
            },
            {
                title: $t('label.created_by'),
                key: 'created_by',
                value: (item) =>
                    `${item.created_by?.staff?.first_name} ${item.created_by?.staff?.last_name}`,
                sortable: false,
            },
            {
                title: $t('label.last_edited_at'),
                key: 'updated_at',
                value: (item) => `${item.updated_at.split('T')[0]}`,
                sortable: false,
            },
            {
                title: $t('label.edited_by'),
                key: 'edited_by',
                value: (item) =>
                    `${item.edited_by?.staff?.first_name} ${item.edited_by?.staff?.last_name}`,
                sortable: false,
            },     
        ]"
        :actions="[
            {
                title: $t('label.view'),
                icon: 'mdi-file-eye-outline',
                action: (item) => viewForm(item),
            },
            {
                title: $t('label.download'),
                icon: 'mdi-download',
                action: (item) => downloadForm(item),
            },
            {
                title: $t('label.delete'),
                icon: 'mdi-delete-forever-outline',
                action: (item) => deleteForm(item),
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
