import { defineStore } from 'pinia';

export const useOptionsStore = defineStore('options', {
    state: () => ({
        roles: [],
        statuses: [],
        provinces: [],
        twoFactorMethods: [],
        securityQuestionTemplates: [],
        securityCheckpoint: false,
        requirementTypes: [],
        requirementOptions: [],
        programTypes: [],
        genderOptions: [],
        timelineTypes: [],
        programOptions: [],
        staffOptions: [],
        clientOptions: [],
        relationships: [],
        templateLabelOptions: [],
        templateTypes: [],
        templateItemTypes: [],
        templateItemOptionTypes: [],
        templateLinkables: [],
        intakeStatusOptions: [],
        applicationStatusOptions: [],
        supportPlanStatusOptions: [],
        supportPlanTypeOptions: [],
        supportPlanGoalTypes: [],
    }),
    actions: {
        reset() {
            this.$reset();
        },
    },
    getters: {
        roleOptions: (state) => {
            return state.roles.map((role) => ({
                label: role.display_name,
                value: role.id,
            }));
        },

        provinceOptions: (state) => {
            return state.provinces.map((province) => ({
                label: province.name,
                value: province.id,
            }));
        },
        // Initialize time options
        // This will generate time options in 15 minute intervals
        // e.g. 12:00 AM, 12:15 AM, 12:30 AM, 12:45 AM, 01:00 AM, 01:15 AM, ...
        timeOptions: (_) => {
            const options = [];
            const pad = (num) => (num < 10 ? '0' : '') + num;

            for (let hour = 0; hour < 24; hour++) {
                for (let minutes = 0; minutes < 60; minutes += 15) {
                    const labelHour = hour % 12 === 0 ? 12 : hour % 12;
                    const labelMinutes = pad(minutes);
                    const period = hour < 12 ? 'AM' : 'PM';
                    const label = `${labelHour}:${labelMinutes} ${period}`;
                    const value = `${pad(hour)}:${labelMinutes}:00`;

                    options.push({
                        label: label,
                        value: value
                    });
                }
            }

            return options;
        },

        relationshipOptions: (state) => {
            return state.relationships.map((relationship) => ({
                label: relationship.name,
                value: relationship.id,
            }));
        },
    }
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(useOptionsStore.acceptHMRUpdate);
}
