<script setup>
import { ref, watch } from 'vue';
import { useOptionsStore } from '@/stores/options';
import { useApplicationStore } from '@/stores/application';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toastification';
import { trans } from 'laravel-vue-i18n';

const { applicationStatusOptions } = storeToRefs(useOptionsStore());
const { update } = useApplicationStore();

const toast = useToast();

const props = defineProps({
    client: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    status_id: null,
});

watch(() => props.client, (client) => {
    if (client) {
        form.value.status_id = client.application.status_id;
        form.value.hash = client.application.hash;
    }
}, { immediate: true });

const saveApplicationStatus = async () => {
    try {
        await update(form.value);
        toast.success(trans('label.saved'));
    } catch (error) {
        toast.error(trans('label.error'));
    }
};

</script>

<template>
<div>
    <div class="md:grid md:grid-cols-2 md:gap-4 md:w-2/3 p-2">
        <StyledDropdown
            v-model="form.status_id"
            :placeholder="$t('label.client_application_status')"
            :options="applicationStatusOptions"
            name="client_application_status"
            :label="$t('label.client_application_status')" />
    </div>

    <v-divider class="col-span-full mb-4" />

    <div class="col-span-full text-lg mb-4">{{ $t('label.client_forms') }}</div>

    <v-btn @click="saveApplicationStatus">{{ $t('label.save') }}</v-btn>
</div>
</template>
