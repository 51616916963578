<script setup>
import { ref, watch, computed } from 'vue';
import { useTemplateStore } from '@/stores/template';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

import WYSIWYG from '@/components/WYSIWYG';

/**********************************************************************
 *      Form Logic
 **********************************************************************/
const { active } = storeToRefs(useTemplateStore());
const { update } = useTemplateStore();

const {
    templateLabelOptions,
    templateTypes,
    templateItemTypes,
    templateItemOptionTypes,
    templateLinkables,
} = storeToRefs(useOptionsStore());

const form = ref({
    title: '',
    type: null,
    linkables: [],
    labels: [],
    items: [],
});

// Restore the form
watch(
    [
        active,
        templateTypes,
        templateLinkables,
        templateLabelOptions,
        templateItemTypes,
        templateItemOptionTypes,
    ],
    ([
        template,
        types,
        linkables,
        labelOptions,
        itemTypes,
        itemOptionTypes,
    ]) => {
        form.value.hash = template.hash;
        form.value.title = template.title;
        form.value.type = types.find(
            (type) => type.value === template.type_id,
        )?.value;
        form.value.linkables = linkables
            .filter((linkable) =>
                template.linkables
                    .map((l) => l.class_type)
                    .includes(linkable.value),
            )
            .map((l) => l.value);

        // No idea why I need to do this - this throws unless I check here
        // but for everything else I don't need to check. Weird!
        if (template && template.labels) {
            form.value.labels = labelOptions
                .filter((label) =>
                    template.labels.map((l) => l.id).includes(label.value),
                )
                .map((l) => l.value);
        }

        let templateItems = [];

        if (template.items) {
            template.items.forEach((item) => {
                let itemOptions = [];

                item.item_options.forEach((option) => {
                    let tableColumnType = itemOptionTypes.find(
                        (type) => type.value === option.table_column_type?.id,
                    )?.value;

                    itemOptions.push({
                        title: option.title,
                        label: option.label,
                        value: option.value,
                        table_column_type: tableColumnType,
                        requires_response: option.requires_response,
                    });
                });

                templateItems.push({
                    id: item.id,
                    order: item.order,
                    title: item.title,
                    type: itemTypes.find((type) => type.value === item.type_id)?.value,
                    is_editable: item.is_editable,
                    has_options: item.has_options,
                    is_multiselect: item.is_multiselect,
                    item_options: itemOptions,
                });
            });

            form.value.items = templateItems;
        }
    },
    { immediate: true },
);

/**********************************************************************
 *      Collapse Logic
 **********************************************************************/

const collapsedIndexes = ref([]);

const isNotCollapsed = (index) => {
    return !collapsedIndexes.value.includes(index);
};

const toggleCollapse = (index) => {
    if (!isNotCollapsed(index)) {
        collapsedIndexes.value = collapsedIndexes.value.filter((i) => i !== index);
    } else {
        collapsedIndexes.value.push(index);
    }
};

const collapseAll = () => {
    collapsedIndexes.value = Array.from({ length: form.value.items.length }, (v, k) => k);
};

const expandAll = () => {
    collapsedIndexes.value = [];
};

/**********************************************************************
 *      Updating
 **********************************************************************/

const updateTemplate = async () => {
    const response = await update({
        hash: form.value.hash,
        title: form.value.title,
        type: form.value.type,
        linkables: form.value.linkables,
        labels: form.value.labels,
        items: form.value.items
    });

    const newTemplate = response.data.new_template;

    // Force refresh the page to the new template, set url to http://local.horizon.test/templates/{hash}/details
    window.location.href = `/templates/${newTemplate.hash}/details`;
};

/**********************************************************************
 *      Helpers
 **********************************************************************/

// Handle the content update from the WYSIWYG editor
const handleItemContentUpdate = (content, item) => {
    item.title = content;
};

const itemIsTableType = (item) => {
    if (!templateItemTypes) {
        return false;
    }

    return (
        templateItemTypes.value.find((type) => type.value === item.type)
            ?.label == 'Table'
    );
};

const itemIsTypeOf = (item, types) => {
    if (!templateItemTypes) {
        return false;
    }
    return types.includes(
        templateItemTypes.value.find((type) => type.value === item.type)?.label,
    );
};

const getOrderOptions = computed(() => {
    return Array.from({ length: form.value.items.length }, (v, k) => k + 1);
});

const addItem = function () {
    form.value.items.push({ 
        id: Math.random().toString(36),  // Randomly generate an ID, this is used to help remove the items from the list during editing
        order: form.value.items.length + 1,
        title: '',
        type: null,
        is_editable: false,
        has_options: false,
        is_multiselect: false,
        item_options: [],
    });
};

const removeItem = function (item) {
    form.value.items = form.value.items.filter((i) => i.id !== item.id);
};

const addOption = function (item) {
    item.item_options.push({
        title: '',
        label: '',
        value: '',
        table_column_type: null,
        requires_response: false,
    });
};

const removeOption = function (item, option) {
    item.item_options = item.item_options.filter(
        (opt) => opt.title !== option.title,
    );
};

const getGridClass = (item) => {
    return itemIsTypeOf(item, ['Response Field'])
        ? 'grid-cols-2 lg:grid-cols-6'
        : 'grid-cols-2 lg:grid-cols-5';
};
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb :index-override="[0, 2]" />
    </template>
    <template #title>{{
        $t("label.template_details", { title: active?.title ?? "" })
    }}</template>
    <div>
        <div class="bg-primary w-full text-white font-bold mb-8 text-center uppercase text-sm">{{ $t("label.template_version") }}: {{ active?.version_info?.version }}</div>
        <form
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4"
            @submit.prevent="save">
            <StyledInput
                v-model="form.title"
                :label="$t('label.title')"
                name="title" />
            <StyledDropdown
                v-model="form.type"
                :label="$t('label.type')"
                :placeholder="$t('label.type')"
                :options="templateTypes"
                name="type" />
            <StyledDropdown
                v-model="form.linkables"
                multiple
                :label="$t('label.linkables')"
                :placeholder="$t('label.linkables')"
                :options="templateLinkables"
                name="linkables" />
            <StyledDropdown
                v-model="form.labels"
                :label="$t('label.labels')"
                clearable
                multiple
                :placeholder="$t('label.labels')"
                :options="templateLabelOptions"
                name="labels" />
        </form>
        <div class="p-4 px-12">
            <v-divider class="my-4" />
            <div class="flex justify-between mt-12">
                <div class="mb-4 text-xl">
                    {{ $t("label.template_items") }}
                </div>
            </div>
            <div class="flex gap-4">
                <StyledButton @click="collapseAll">
                    <v-icon icon="mdi-chevron-up" />
                    {{ $t("label.collapse_all") }}
                </StyledButton>
                <StyledButton @click="expandAll">
                    <v-icon icon="mdi-chevron-down" />
                    {{ $t("label.expand_all") }}
                </StyledButton>
            </div>
            <div
                v-for="(item, index) in form.items"
                :key="item.id">
                <div
                    :class="[
                        'my-4 pb-6 pt-8 px-12 grid gap-4 border-gray-light border rounded-lg',
                        getGridClass(item),
                    ]">
                    <div class="col-span-full mb-4 flex justify-between">
                        <div class="text-lg self-center">
                            <v-icon
                                :icon="isNotCollapsed(index) ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                                class="cursor-pointer"
                                @click="toggleCollapse(index)" />
                            {{ $t("label.item") }} #{{ index + 1 }}
                        </div>
                        <StyledButton class="self-center" color="error" @click="removeItem(item)">{{
                            $t("label.remove")
                        }}</StyledButton>
                    </div>
                    <StyledDropdown
                        v-if="isNotCollapsed(index)"
                        v-model="item.order"
                        :clearable="false"
                        :options="getOrderOptions"
                        :label="$t('label.order')" />
                    <StyledInput
                        v-if="!itemIsTypeOf(item, [
                            'Text Editor',
                        ]) && isNotCollapsed(index)"
                        v-model="item.title"
                        class="col-span-2"
                        :label="$t('label.title')"
                        name="title" />
                    <StyledDropdown
                        v-if="isNotCollapsed(index)"
                        v-model="item.type"
                        class="col-span-2"
                        :clearable="false"
                        :options="templateItemTypes"
                        :label="$t('label.type')" />
                    <StyledRoundSwitch
                        v-if="
                            itemIsTypeOf(item, [
                                'Response Field',
                                'Text Editor',
                            ]) && isNotCollapsed(index)
                        "
                        v-model="item.is_editable"
                        :label="$t('label.is_editable')"
                        color="primary"
                        inset
                        name="is_editable" />
                    <div
                        v-if="
                            itemIsTypeOf(item, [
                                'Single Select',
                                'Multi Select',
                                'Checklist',
                            ]) && isNotCollapsed(index)
                        "
                        class="grid grid-cols-1 col-span-full gap-4 p-4">
                        <div v-if="isNotCollapsed(index)" class="mb-4 text-lg col-span-full">
                            {{ $t("label.options") }}
                        </div>
                        <div v-if="isNotCollapsed(index)">
                            <div
                                v-for="opt in item.item_options"
                                :key="`item_${item.id}_opt_${opt.id}`"
                                class="flex flex-row gap-4">
                                <StyledInput
                                    v-model="opt.title"
                                    :label="$t('label.option_title')"
                                    name="option_title" />
                                <StyledRoundSwitch
                                    v-if="itemIsTypeOf(item, ['Checklist'])"
                                    v-model="opt.requires_response"
                                    :label="$t('label.requires_response')"
                                    color="primary"
                                    inset
                                    name="requires_response" />
                                <div
                                    class="flex gap-2 text-red-500 col-span-2 mt-2 cursor-pointer hover:underline">
                                    <v-icon
                                        icon="mdi-trash-can-outline"
                                        @click="removeOption(item, opt)" />
                                </div>
                            </div>
                        </div>
                        <StyledButton v-if="isNotCollapsed(index)" class="w-40" @click="addOption(item)">
                            <div class="flex items-center justify-center">
                                <v-icon class="mr-2" icon="mdi-plus" />
                                {{ $t("label.add_option") }}
                            </div>
                        </StyledButton>
                    </div>
                    <div
                        v-if="itemIsTableType(item) && isNotCollapsed(index)"
                        class="grid grid-cols-1 col-span-full gap-4 p-4 border border-gray-light shadow-md rounded-lg">
                        <div class="mb-4 text-lg">
                            {{ $t("label.table_columns") }}
                        </div>
                        <div
                            v-for="column in item.item_options"
                            :key="`column_item_${item.id}_column_${column.id}`"
                            class="grid grid-cols-12 gap-4">
                            <StyledInput
                                v-model="column.title"
                                class="col-span-5"
                                :label="$t('label.column_title')"
                                name="column_title" />
                            <StyledDropdown
                                v-model="column.table_column_type"
                                class="col-span-5"
                                :label="$t('label.table_column_type')"
                                :options="templateItemOptionTypes"
                                name="table_column_type" />
                            <div
                                class="flex gap-2 text-red-500 col-span-2 mt-2 cursor-pointer hover:underline">
                                <v-icon
                                    icon="mdi-trash-can-outline"
                                    @click="removeOption(item, column)" />
                            </div>
                        </div>
                        <StyledButton class="w-40" @click="addOption(item)">
                            <div class="flex items-center justify-center">
                                <v-icon class="mr-2" icon="mdi-plus" />
                                {{ $t("label.add_column") }}
                            </div>
                        </StyledButton>
                    </div>
                    <div v-if="itemIsTypeOf(item, ['Text Editor'])" class="col-span-full">
                        <WYSIWYG :initial-content="item.title" @contentUpdate="handleItemContentUpdate($event, item)" />
                    </div>  
                </div>
            </div>

            <div
                class="bg-grey-200 my-4 pb-12 pt-12 px-12 border-black border border-dashed rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-100"
                @click="addItem">
                <StyledButton class="flex items-center">
                    <v-icon class="mr-2 text-white" icon="mdi-plus" />
                    {{ $t("label.add_item") }}
                </StyledButton>
            </div>
            <div class="mt-4 p-4 bg-primary uppercase text-center border rounded-md cursor-pointer hover:shadow-md" @click="updateTemplate">
                {{ $t("label.save") }}
            </div>
        </div>
    </div>
</MainContentLayout>
</template>
