<script setup>
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useSupportPlanGoalStore } from '@/stores/supportPlanGoal';
import { router } from '@inertiajs/vue3';

const { searchSupportPlanGoals } = useSupportPlanGoalStore();
const { supportPlanGoals } = storeToRefs(useSupportPlanGoalStore());

import { inject } from 'vue';
const modals = inject('modals');

const props = defineProps({
    supportPlan: {
        type: Object,
        default: () => null
    }
});

const params = ref({
    term: '',
    page: 1,
    per_page: 10,
});

const fetchSupportPlanGoals = () => {
    searchSupportPlanGoals({
        ...params.value,
        hash: props.supportPlan.hash,
    });
};

const openSupportPlanGoalModal = (supportPlanGoal = null) => {
    modals.show('SupportPlanGoalModal', {
        props: {
            mode: supportPlanGoal ? 'Edit' : 'Create',
            goal: supportPlanGoal ? supportPlanGoal : {},
            supportPlan: props.supportPlan,
        }
    }).then(() => {
        fetchSupportPlanGoals();
    });
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    fetchSupportPlanGoals();
};

const accessGoalTrackingEvents = (goal) => {
    router.visit(`/clients/support-plan/goals/${goal.hash}/events`);
};

</script>

<template>
<div>
    <div class="flex justify-end pb-4">
        <StyledButton @click="openSupportPlanGoalModal(null)">
            <FontAwesomeIcon icon="plus" class="mr-1" />{{ $t('label.new_goal') }}
        </StyledButton>
    </div>
    <DataTable
        v-if="supportPlanGoals && supportPlanGoals.data"
        :data="supportPlanGoals"
        :headers="[
            { title: $t('label.description'), key: 'description', value: item => `${item.description}` },
            { title: $t('label.outcomes'), key: 'outcome', value: item => `${item.outcome}` },
            { title: $t('label.support_staff_strategy'), key: 'support_staff_strategy', value: item => `${item.support_staff_strategy}` },
            { title: $t('label.support_staff_objectives'), key: 'support_staff_objectives', value: item => `${item.support_staff_objectives}` },
            { title: $t('label.responsible'), key: 'responsible', value: item => item.managers.map(manager => manager.first_name  + ' ' + manager.last_name).join(', ') },
        ]"
        :actions="[
            {
                title: $t('label.edit'),
                icon: 'mdi-pencil',
                action: (item) => openSupportPlanGoalModal(item),
            },
            {
                title: $t('label.events'), // 'access_goal_tracking_events'
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessGoalTrackingEvents(item),
            },
        ]" 
        @table-update="tableUpdate" />
    <div v-else>
        {{ $t('label.no_support_plan_goals') }}
    </div>
</div>
</template>
