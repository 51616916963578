<script setup>
import { ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useClientStore } from '@/stores/client';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { useDateFormatter } from '@/composables/dateFormatter';
import AttendeeSelector from '@/components/Client/AttendeeSelector.vue';

const { updateIntakeTour } = useClientStore();
const { intakeStatusOptions } = storeToRefs(useOptionsStore());

const toast = useToast();
const { formatDOB, formatDate } = useDateFormatter();

const props = defineProps({
    client: {
        type: Object,
        default: () => null
    }
});

const form = ref([]);
const saving = ref(false);

watch(() => props.client, (client) => {
    if (!saving.value && client && client.intake_tour) {
        form.value = {
            hash: client.intake_tour.hash ?? '',
            tenant_id: client.intake_tour.tenant_id ?? '',
            client_id: client.intake_tour.client_id ?? '',
            status_id: client.intake_tour.status_id ?? null,
            scheduled_at: client.intake_tour.scheduled_at ? formatDOB(client.intake_tour.scheduled_at) : null,
            attendees: client.intake_tour.attendees ?? [],
        };
    }
}, { immediate: true });

const save = () => {
    saving.value = true;
    updateIntakeTour({
        client_hash: props.client.hash,
        intake_tour_hash: form.value.hash,
        ...form.value,
        scheduled_at: formatDate(form.value.scheduled_at),
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    }).finally(() => {
        saving.value = false;
    });
};

const updateAttendees = (newAttendees) => {
    form.value.attendees = newAttendees;
};
</script>

<template>
<div>
    <div class="text-lg mb-2 md:mb-4">{{ $t('label.intake_tour') }}</div>
    <div
        class="md:grid md:grid-cols-2 md:gap-4">
        <StyledDropdown
            v-model="form.status_id"
            :placeholder="$t('label.intake_tour_status')"
            :options="intakeStatusOptions"
            name="requirement_status"
            :label="$t('label.intake_tour_status')" />
        <StyledDatepicker
            v-model="form.scheduled_at"
            :placeholder="$t('label.scheduled_at')"
            name="scheduled_at"
            type="text"
            :label="$t('label.scheduled_at')" />
        
        <div class="md:col-span-full text-lg mb-2 md:mb-4">{{ $t('label.attendees') }}</div>

        <AttendeeSelector
            class="col-span-4"
            :initial-attendees="form.attendees"
            @attendees-updated="updateAttendees($event)" />

        <div class="my-4">
            <StyledButton
                @click="save()">
                {{ $t('label.save') }}
            </StyledButton>
        </div>
    </div>
</div>
</template>
