<script setup>
import { ref } from 'vue';
import { useSupportPlanGoalEventStore } from '@/stores/supportPlanGoalEvent';
import { storeToRefs } from 'pinia';

import EventDetailsTab from '../../components/Client/EventDetailsTab.vue';

const { active } = storeToRefs(useSupportPlanGoalEventStore());

const tab = ref('details');

</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb :index-override="[0,2]" />
    </template>
    <template #title>{{ $t('title.event_details', { name: `${active.support_plan_goal?.support_plan?.client?.first_name} ${active.support_plan_goal?.support_plan?.client?.last_name}` ?? '' }) }}</template>
    <template #actions />
    <div>
        <v-card>
            <v-tabs v-model="tab">
                <v-tab value="details">{{ $t('label.details') }}</v-tab>
            </v-tabs>
            <v-card-text>
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="details">
                        <EventDetailsTab :event="active" />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-card>
    </div>
</MainContentLayout>
</template>
