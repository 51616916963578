<script setup>
import { ref, watch } from 'vue';
import { useTimelineStore } from '@/stores/timeline';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { useDateFormatter } from '@/composables/dateFormatter';
import { trans } from 'laravel-vue-i18n';
const { updateTimeline } = useTimelineStore();
const { formatDate, parseLocalDateFromString } = useDateFormatter();
import { useToast } from 'vue-toastification';
import FormManager from '../../components/Forms/FormManager.vue';
const toast = useToast();

/**********************************************************************
 *      Form Logic
 **********************************************************************/
const { active } = storeToRefs(useTimelineStore());

const {
    timelineTypes,
    timeOptions,
    staffOptions,
    clientOptions,
    programOptions,
} = storeToRefs(useOptionsStore());

const form = ref({
    //
});

const update = async () => {
    try {
        await updateTimeline({
            hash: active.value.hash,
            ...form.value,
            date: formatDate(form.value.date),
        });
        toast.success(trans('label.saved'));
    } catch (error) {
        toast.error(trans('label.error'));
    }
};

// Restore the form
watch(
    [active],
    ([timeline]) => {
        if (timeline) {
            form.value.type = timeline.type_id;
            form.value.clients = timeline.clients.map((client) => client.id);
            form.value.programs = timeline.programs.map(
                (program) => program.id,
            );
            form.value.staff = timeline.staff.map((staff) => staff.id);
            form.value.date = parseLocalDateFromString(timeline.date);
            form.value.time = timeline.time;
        }
    },
    { immediate: true },
);
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb :index-override="[0, 2]" />
    </template>
    <template #title>{{
        $t("label.timeline_event_details", { title: active?.title ?? "" })
    }}</template>
    <v-card class="pa-4">
        <v-card-title>{{ $t('label.timeline_event_details') }}</v-card-title>
        <v-card-text>
            <v-form @submit.prevent="update">
                <v-row dense>
                    <v-col cols="12" md="4">
                        <StyledDatepicker
                            v-model="form.date"
                            :label="$t('label.date')"
                            view-mode="month"
                            name="date" />
                    </v-col>

                    <v-col cols="12" md="4">
                        <StyledDropdown
                            v-model="form.time"
                            :options="timeOptions"
                            :label="$t('label.time')"
                            name="time" />
                    </v-col>

                    <v-col cols="12" md="4">
                        <StyledDropdown
                            v-model="form.type"
                            clearable
                            :placeholder="$t('label.type')"
                            :options="timelineTypes"
                            name="type" />
                    </v-col>

                    <v-col cols="12">
                        <StyledDropdown
                            v-model="form.clients"
                            multiple
                            searchable
                            :options="clientOptions"
                            :label="$t('label.clients_involved')"
                            name="clients" />
                    </v-col>

                    <v-col cols="12">
                        <StyledDropdown
                            v-model="form.staff"
                            multiple
                            searchable
                            :options="staffOptions"
                            :label="$t('label.staff_involved')"
                            name="staff" />
                    </v-col>

                    <v-col cols="12">
                        <StyledDropdown
                            v-model="form.programs"
                            multiple
                            searchable
                            :options="programOptions"
                            :label="$t('label.programs_involved')"
                            name="programs" />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
  
        <v-card-actions>
            <v-spacer />
            <StyledButton
                @click="update"><v-icon icon="mdi-content-save-outline" class="mr-1" />
                {{ $t("label.save") }}
            </StyledButton>
        </v-card-actions>
    </v-card>

    <div class="mt-8"> 
        <FormManager 
            type="timeline"
            :formable="active" />
    </div>
</MainContentLayout>
</template>
